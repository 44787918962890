import React from 'react';
import { Link } from 'react-scroll';

const Navbar = () => {
  return (
    <div className="navbar navbar-expand-lg justify-content-lg-around" id="navbar-background">
      <span>
        <Link
          class="nav-link cursor"
          to="about"
          smooth
          duration={1000}
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          About Me
        </Link>
      </span>
      <span>
        <Link
          class="nav-link cursor"
          to="skills"
          smooth
          duration={1000}
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          Skills{' '}
        </Link>
      </span>
      <span>
        <Link
          class="nav-link cursor"
          to="projects"
          smooth
          duration={1000}
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          Projects{' '}
        </Link>
      </span>
      <span>
        <Link
          class="nav-link cursor"
          to="contact"
          smooth
          duration={1000}
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          Contact
        </Link>
      </span>
    </div>
  );
};

export default Navbar;
