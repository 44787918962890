import React from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import Title from '../Title/Title';

const Skills = () => {
  return (
    <section id="skills">
      <Container>
        <Title title="Skills" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="skills-wrapper row">
            <ul className="skills-wrapper__text list-group list col-md-4">
              <li className="list-item header">Languages</li>
              <li className="list-item">HTML/CSS</li>
              <li className="list-item">Javascript</li>
              <li className="list-item">Python</li>
            </ul>

            <ul className="skills-wrapper__text list-group list col-md-4">
              <li className="list-item header">Frameworks</li>
              <li className="list-item">React</li>
              <li className="list-item">Bootstrap</li>
              <li className="list-item">Reactstrap</li>
              <li className="list-item">Flask</li>
            </ul>

            <ul className="skills-wrapper__text list list-group list col-md-4">
              <li className="list-item header">Technologies</li>
              <li className="list-item">Git/GitHub</li>
              <li className="list-item">Visual Studio Code</li>
              <li className="list-item">Node/NPM</li>
              <li className="list-item">RESTful API&#39;s</li>
              <li className="list-item">Postman</li>
              <li className="list-item">SQL/PostgreSQL</li>
              <li className="list-item">Heroku</li>
            </ul>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Skills;
